import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col, Card } from 'react-bootstrap';
import { AppState } from '../../store/configureStore';
import { bindActionCreators } from 'redux';
import WellBeingSummary from '../cards/summary/WellBeingSummary';
import { LifeAndDisabilitySummary } from '../cards/summary/LifeAndDisabilitySummary';
import HealthSummary from '../cards/summary/HealthSummary';
import RetirementSummary from '../cards/summary/RetirementSummary';
import Skeleton from 'react-loading-skeleton';
import { AmountPanelSmall } from '../AmountPanelSmall';
import BeneficiaryReviewModal from '../BeneficiaryReviewModal';
import { displayPageActionCreators } from '../../store/DisplayPageStore';
import { GatewayPath, useWindowDimensions } from '@wespath/gateway-navigation'
import { AppDispatch } from '../..';
import EYSearchBanner from '../EYSearchBanner';
import FetchData from '../FetchData';
import { EY, Health, WellBeing } from '../../definitions/Claims';
import UnlockBanner from '../UnlockBanner';
import { accountActionCreators } from '../../store/AccountStore';
import SummaryCarousel from '../SummaryCarousel';
import { EnrollmentBanner } from '../benefitSolverEnrollment/EnrollmentBanner';
import { CollapsibleCard } from '../CollapsibleCard';

type TBenefitSummaryProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const BenefitsSummary = (props: TBenefitSummaryProps) => {
    const {
        participantNumber,
        showRetirement,
        showHealth,
        showWellBeing,
        showLifeAndDisability,
        goToPage,
        redirectFromSummary,
        setRedirectFromSummary,
        accessDenied,
        columnCount,
        hasClaim_EySearch,
        claimsLoaded,
        retirementIsLoaded,
        lifeAndDisIsLoaded,
        healthIsLoaded,
        wellBeingIsLoaded,
        headerMenu
    } = props;    
    const dispatch: AppDispatch = useDispatch();
    const isFinPlanner = hasClaim_EySearch;

    //Check if data is loaded and redirect to specific page if that is the only data available
    const dataIsLoaded = retirementIsLoaded && lifeAndDisIsLoaded && healthIsLoaded && wellBeingIsLoaded;

    useEffect(() => {

        //Redirect if user has access to target page
        if (redirectFromSummary && claimsLoaded && isValidRedirect(redirectFromSummary))
            goToPage(redirectFromSummary)       

        //Wait for all pages to load to see what the default page should be
        else if (dataIsLoaded && !isFinPlanner) {
            if (!showRetirement && !showHealth && !showWellBeing && !showLifeAndDisability)
                accessDenied("User does not have Retirement, Health, WellBeing, or LifeAndDisability");
            else if (showRetirement && !showHealth && !showWellBeing && !showLifeAndDisability)
                goToPage(GatewayPath.Retirement);
            else if (!showRetirement && showHealth && !showWellBeing && !showLifeAndDisability)
                goToPage(GatewayPath.Health);
            else if (!showRetirement && !showHealth && showWellBeing && !showLifeAndDisability)
                goToPage(GatewayPath.WellBeing);
            else if (!showRetirement && !showHealth && !showWellBeing && showLifeAndDisability)
                goToPage(GatewayPath.LifeAndDisability);

            //If data is loaded and the page hasn't redirected yet, consider the target page to be invalid
            else if (redirectFromSummary && headerMenu?.menus) {
                console.log("Unable to redirect to", redirectFromSummary)
                dispatch(setRedirectFromSummary({ redirectFromSummary: undefined }))
            }
        }

    }, [dataIsLoaded, showRetirement, showHealth, showWellBeing, showLifeAndDisability, goToPage, isFinPlanner, accessDenied, headerMenu]);

    function isValidRedirect(redirectFromSummary: GatewayPath | string): boolean {
        let isValid = false

        //Check if it's a core page that the user has access to
        if ((redirectFromSummary === GatewayPath.Retirement && showRetirement) ||
            ((redirectFromSummary === GatewayPath.Health || redirectFromSummary === GatewayPath.HealthFlexBenefits) && showHealth) ||
            (redirectFromSummary === GatewayPath.WellBeing && showWellBeing) ||
            (redirectFromSummary === GatewayPath.LifeAndDisability && showLifeAndDisability)
        ) {
            isValid = true
        }

        //Check if it's a menu item page that the user has access to
        headerMenu?.menus?.forEach((menu) => {

            menu.children.forEach((child) => {

                //Check first children level
                if (child.link === redirectFromSummary)
                    isValid = true  

                //Check second children level
                child.children?.forEach((secondChild) => {
                    if (secondChild.link === redirectFromSummary)
                        isValid = true                    
                })
            })
        })

        return isValid
    }

    useEffect(() => {
        document.title = "Benefits Access - Summary"
    }, []);

    const colClass = () => {
        return "summaryCard col-12 col-md-6 " + (columnCount === 4 ? "col-lg-6 col-xl-3" : "col-lg-4");
    }

    const [expandCards, setExpandCards] = useState(dataIsLoaded);
    useEffect(() => {
        if (!expandCards && (dataIsLoaded || (showRetirement && showHealth && showWellBeing && showLifeAndDisability)))
            setTimeout(() => {
                setExpandCards(true);
            }, 500);
    }, [dataIsLoaded, showRetirement, showHealth, showWellBeing, showLifeAndDisability]);

    return (<>

        <EYSearchBanner />

        <UnlockBanner />

            {/*Add padding above the banners*/}
            <div style={{ height: '0.25em' }} />

            <EnrollmentBanner customClass="isSummary">
               <SummaryCarousel />
            </EnrollmentBanner>
            

        <FetchData />

        <BeneficiaryReviewModal />

        {claimsLoaded && participantNumber && <Row className="justify-content-center" style={{ marginTop: '1em' }}>

            {/*Show only 2 loading columns before data is loaded*/}
            {(!isFinPlanner && columnCount < 2) || (isFinPlanner && columnCount < 1)
                ? <>

                    <RetLoadingSkeleton />

                    <GenericLoadingSkeleton />

                </>
                : <>

                    {!retirementIsLoaded
                        ? <RetLoadingSkeleton />
                        : <>
                            {showRetirement && <Col className={colClass()}>
                                <RetirementSummary expandCard={expandCards} />
                            </Col>}
                        </>
                    }

                    {!healthIsLoaded
                        ? <GenericLoadingSkeleton />
                        : <>
                            {showHealth && <Col className={colClass()}>
                                <HealthSummary expandCard={expandCards} />
                            </Col>}
                        </>
                    }

                    {!wellBeingIsLoaded
                        ? <GenericLoadingSkeleton />
                        : <>
                            {showWellBeing && <Col className={colClass()}>
                                <WellBeingSummary expandCard={expandCards} />
                            </Col>}
                        </>
                    }

                    {!lifeAndDisIsLoaded
                        ? <GenericLoadingSkeleton />
                        : <>
                            {showLifeAndDisability && <Col className={colClass()}>
                                <LifeAndDisabilitySummary expandCard={expandCards} />
                            </Col>}
                        </>
                    }

                </>
            }

        </Row>}

    </>)
};

function mapStateToProps(state: AppState) {
    const { hsa, healthPlans, cpp, umLifeOptions, balance, benefitProjection, investments, monthlyBenefit, wellBeing, medicare } = state;
    const { showHealth, showLifeAndDisability, showRetirement, showWellBeing } = state.displayPage
    const { headerMenu } = state.retirementMenu

    const hasClaim_WellBeingView = state.claims.claims?.claims?.find(c => c === WellBeing.View);
    const hasClaim_HealthView = state.claims.claims?.claims?.find(c => c === Health.View);

    const retirementIsLoaded = showRetirement || (balance.isLoaded && investments.isLoaded && benefitProjection.isLoaded && monthlyBenefit.isLoaded);
    const lifeAndDisIsLoaded = showLifeAndDisability || (cpp.isLoaded && umLifeOptions.isLoaded);
    const healthIsLoaded = !hasClaim_HealthView || showHealth || (hsa.isLoaded && healthPlans.isLoaded);
    const wellBeingIsLoaded = !hasClaim_WellBeingView || showWellBeing || wellBeing.isLoaded; 

    const columnCount = Number(showHealth) + Number(showLifeAndDisability) + Number(showRetirement) + Number(showWellBeing)

    return {
        balance,
        benefitProjection,
        investments,
        monthlyBenefit,
        cpp,
        umLifeOptions,
        hsa,
        healthPlans,
        wellBeing,
        medicare,
        participantNumber: state.account.participantNumber,
        hasClaim_EySearch: state.claims.claims?.claims?.find(c => c === EY.Search),
        claimsLoaded: state.claims?.isLoaded,
        retirementIsLoaded,
        lifeAndDisIsLoaded,
        healthIsLoaded,
        wellBeingIsLoaded,
        columnCount,
        headerMenu,
        ...state.displayPage
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    const { goToPage, setRedirectFromSummary } = displayPageActionCreators;
    const { accessDenied } = accountActionCreators;

    return bindActionCreators({
        goToPage,
        setRedirectFromSummary,
        accessDenied
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BenefitsSummary);

const RetLoadingSkeleton = () => {
    const { isSmallScreen } = useWindowDimensions();
    return (
        <Col className="summaryCard col-12 col-md-6 col-lg-4">
            <CollapsibleCard headerClassName="loading-color-bg" headerContent={isSmallScreen ? <Skeleton width={'80%'} /> : undefined}>
                <Card.Body className="pt-3">
                    <AmountPanelSmall label="" isLoading={true} />
                    <AmountPanelSmall label="" isLoading={true} />
                    <AmountPanelSmall label="" isLoading={true} />
                    <AmountPanelSmall label="" isLoading={true} />
                </Card.Body>
            </CollapsibleCard>
        </Col>
    )
};

const GenericLoadingSkeleton = () => {
    const { isSmallScreen } = useWindowDimensions();
    return (
        <Col className="summaryCard col-12 col-md-6 col-lg-4">
            <CollapsibleCard headerClassName="loading-color-bg" headerContent={isSmallScreen ? <Skeleton width={'80%'} /> : undefined}>
                <Card.Body className="pt-3">
                    <div className="mt-2"><Skeleton width={'50%'} /></div>
                    <div><Skeleton width={'90%'} /></div>
                    <div><Skeleton width={'50%'} /></div>
                    <hr />
                    <div className="mt-2"><div><Skeleton width={'90%'} /></div></div>
                    <div><Skeleton width={'90%'} /></div>
                    <div><Skeleton width={'90%'} /></div>
                    <hr />
                </Card.Body>
            </CollapsibleCard>
        </Col>
    )
};