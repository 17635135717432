import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'
import { accountReducer } from './AccountStore';
import { loginHelpReducer } from './LoginHelpStore';
import { cppReducer } from './CppStore';
import { umLifeOptionsReducer } from './UmLifeOptionsStore';
import { routerMiddleware } from 'connected-react-router';
import { assetAllocationReducer } from './AssetAllocationStore';
import { benefitProjectionReducer } from './BenefitProjectionStore';
import { investmentsReducer } from './InvestmentsStore';
import { transactionsReducer } from './TransactionsStore';
import { monthlyBenefitReducer } from './MonthlyBenefitStore';
import { balanceReducer } from './BalanceStore';
import { pendingActionsReducer } from './PendingActionsStore';
import { lnMultiFactorReducer } from './LNMultiFactorStore';
import { displayPageReducer } from './DisplayPageStore';
import { contributionsReducer } from './ContributionsStore';
import { eyReducer } from './EyEligStore';
import { personalInfoReducer } from './accountManagement/PersonalInfoStore';
import { deliveryPreferencesReducer } from './accountManagement/DeliveryPreferencesStore';
import { eyAuthReducer } from './accountManagement/EyAuthorizationStore';
import { contactInfoReducer } from './accountManagement/ContactInfoStore';
import { passwordReducer } from './accountManagement/PasswordStore';
import { usernameReducer } from './accountManagement/UsernameStore';
import { activityReducer } from './ActivityStore';
import { hsaReducer } from './HealthSavingsAccountStore';
import { healthPlansReducer } from './HealthPlanStore';
import { wellBeingReducer } from './WellBeingStore';
import { authMultiFactorReducer } from './AuthMultiFactorStore';
import { beneReviewReducer } from './BeneReviewStore';
import { retirementMenuReducer } from './RetirementMenuStore';
import { eySearchReducer } from './EySearchStore';
import { medicareReducer } from './MedicareStore';
import { claimsReducer } from './ClaimsStore';
import { unlockReducer } from './UnlockStore';
import { cmsReducer } from './CmsStore'
import { errHandlingReducer } from './ErrHandling';
import { configureStore } from '@reduxjs/toolkit';
import { googleAnalyticsIdReducer } from './GoogleAnalyticsIdStore';
import {
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist'
import { applicationTimeoutReducer } from './ApplicationTimeoutStore';
import { BaCmsReducer } from './BACmsStore';
import { LsriReducer } from './LsriStore';
import { settingUpRetirementIncomeReducer } from './SettingUpRetirementIncomeStore';
import { selfManagedRetirementIncomeReducer } from './SelfManagedRetirementIncomeStore';
import { mppDistributionReducer } from './MPPDistributionStore';
import { lifeStageInvestmentOptionsReducer } from './LifeStageInvestmentOptionsStore';
import { investmentsSummaryReducer } from './InvestmentsSummaryStore';
import { bannerOptionsReducer } from './BannerStore';
import { manageDirectDepositReducer } from './ManageDirectDepositStore';
import { paymentsInfoReducer } from './PaymentsInfoStore';




const reducers = {
    account: accountReducer,
    loginHelp: loginHelpReducer,
    lnMultiFactor: lnMultiFactorReducer,
    cpp: cppReducer,
    umLifeOptions: umLifeOptionsReducer,
    ey: eyReducer,
    assetAllocation: assetAllocationReducer,
    benefitProjection: benefitProjectionReducer,
    investments: investmentsReducer,
    transactions: transactionsReducer,
    monthlyBenefit: monthlyBenefitReducer,
    balance: balanceReducer,
    contributions: contributionsReducer,
    pendingActions: pendingActionsReducer,
    personalInfo: personalInfoReducer,
    contactInfo: contactInfoReducer,
    deliveryPreferences: deliveryPreferencesReducer,
    eyAuthorization: eyAuthReducer,
    password: passwordReducer,
    username: usernameReducer,
    displayPage: displayPageReducer,
    activity: activityReducer,
    hsa: hsaReducer,
    healthPlans: healthPlansReducer,
    wellBeing: wellBeingReducer,
    authMultiFactor: authMultiFactorReducer,
    beneReview: beneReviewReducer,
    retirementMenu: retirementMenuReducer,
    eySearch: eySearchReducer,
    medicare: medicareReducer,
    claims: claimsReducer,
    unlock: unlockReducer,
    cms: cmsReducer,
    errHandling: errHandlingReducer,
    googleAnalyticsId: googleAnalyticsIdReducer,
    applicationTimeout: applicationTimeoutReducer,
    baCms: BaCmsReducer,
    lsri: LsriReducer,
    settingUpRetirementIncome: settingUpRetirementIncomeReducer,
    selfManagedRetirementIncome: selfManagedRetirementIncomeReducer,
    mppDistribution: mppDistributionReducer,
    lifeStateInvestmentOptions: lifeStageInvestmentOptionsReducer,
    investmentsSummary: investmentsSummaryReducer,
    bannerOptions: bannerOptionsReducer,
    manageDirectDeposit: manageDirectDepositReducer,
    paymentsInfo: paymentsInfoReducer
};


// Create browser history to use in the Redux store
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')!;
export const history = createBrowserHistory({ basename: baseUrl });

const rootReducer = combineReducers({
    router: connectRouter(history),
    ...reducers,
});

const persistConfig = {
    key: 'root',
    storage: storageSession,
    blacklist: ['router', 'retirementMenu']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export type AppState = ReturnType<typeof persistedReducer>;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function configurePersistedStore(initialState: AppState) {


    const store = configureStore({
        reducer: persistedReducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        }).concat(routerMiddleware(history)),
        devTools: process.env.NODE_ENV !== 'production',
        preloadedState: initialState
    })
    const persistor = persistStore(store)

    return { store, persistor };
}