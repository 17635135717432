import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { bindActionCreators } from '@reduxjs/toolkit';
import { accountActionCreators } from '../../store/AccountStore';
import { Button, Col, Card, Form, Alert, Spinner, Row } from 'react-bootstrap';
import { GatewayPath } from '@wespath/gateway-navigation'
import { Link, NavLink } from 'react-router-dom';
import { loginHelpCreators } from '../../store/LoginHelpStore';
import { faLockAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stateNeedsLoading } from '../../functions/stateNeedsLoading';
import Skeleton from 'react-loading-skeleton';
import { AppDispatch } from '../..';
import { activityActionCreators } from '../../store/ActivityStore';
import { cmsActionCreators } from '../../store/CmsStore';
import { client } from '../../App';
import { BeginSession, GetThreatMetrixParametersResponse, LogActivity } from '../../Gateway.dtos';
import { threatMetrixReprofile } from '../../functions/threatMetrixReprofile';
import { resetApplication } from '../../functions/resetApplication';
import { push } from 'connected-react-router';

type TLoginProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Login = (props: TLoginProps) => {
    const { error, errorMessage, accessDenied } = props;
    const dispatch: AppDispatch = useDispatch(); 

    useEffect(() => {
        document.title = "Benefits Access - Login"
    }, []);

    const isDevelopment = process.env.NODE_ENV === 'development';
    const [username, setUsername] = useState(localStorage?.getItem("userName") ?? '');
    const [password, setPassword] = useState(isDevelopment ? 'topsecret123!' : '');
    const [rememberUserName, setRememberUserName] = useState(localStorage?.getItem("userName") !== null);
    const [isLastAttempt, setIsLastAttempt] = useState(false);
    const [attemptCount, setAttemptCount] = useState(0);
    const [threatMetrixSessionId, setThreatMetrixSessionId] = useState("");

    const [showUsernameErr, setShowUsernameErr] = useState(false);
    function isUsernameValid(val: string) {
        const isValid = val.length > 0;
        setShowUsernameErr(!isValid && attemptCount > 0);
        return isValid;
    }

    const [showPasswordErr, setShowPasswordErr] = useState(false);
    function isPasswordValid(val: string) {
        const isValid = val.length > 0;
        setShowPasswordErr(!isValid && attemptCount > 0);
        return isValid;
    }

    const isInlineErrors = () => {
        let isErr = false

        //Check for errors so that they all display
        isErr = !isUsernameValid(username) || isErr;
        isErr = !isPasswordValid(password) || isErr;

        return isErr;
    }

    const login = () => {
        if (!isInlineErrors()) {
            setAttemptCount(attemptCount + 1);
            props.login(username, password, threatMetrixSessionId, false, rememberUserName);
        }
    }

    const cmsData = props.cmsData.fragments?.find(f => f.name === "LoginMessage");

    const threatMetrixProfileCompleteHandler = (event: MessageEvent<string>) => {
        if (typeof event.data === "string") {
            const parts = event.data.split(":")

            if (parts.length === 2 && parts[0] === "tmx_profiling_complete") {
                setThreatMetrixSessionId(parts[1])
            }
        }
    }
    

    useEffect(() => {

        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }

        //Reset login help props
        if (props.regStepNum > 1)
            props.resetState();
        
        //If inline errors are displayed, re-evaluate them on changes
        if (error || showUsernameErr) { isUsernameValid(username); }
        if (error || showPasswordErr) { isPasswordValid(password); }

    });

    useEffect(() => {
        window.addEventListener("message", threatMetrixProfileCompleteHandler, false);
        

        client.get(new BeginSession())
            .then((parameters) => {
                client.post(new LogActivity({
                    page: GatewayPath.Login,
                    sessionId: parameters.webSessionId
                }));
                threatMetrixReprofile(new GetThreatMetrixParametersResponse({
                    endPoint: parameters.endPoint,
                    organizationId: parameters.organizationId,
                    sessionId: parameters.threatMetrixSessionId
                }));
                setTimeout(() => window.postMessage(`tmx_profiling_complete:${parameters.threatMetrixSessionId}`, "*"), 2000)
            })
            .catch(() => {
                dispatch(resetApplication());
                dispatch(push(GatewayPath.Maintenance));
            });

        return () => window.removeEventListener("message", threatMetrixProfileCompleteHandler);
    }, [dispatch])

    useEffect(() => {

        if (error) {
            setUsername('');
            setPassword('');
        }

    }, [error, setUsername, setPassword]);

    useEffect(() => {

        if (errorMessage?.toLowerCase().includes("last attempt"))
            setIsLastAttempt(true);

    }, [errorMessage, setIsLastAttempt, attemptCount, accessDenied]);


    if (!threatMetrixSessionId) {
        return <LoadingSkeleton />;
    }
        
    return (<>
        {cmsData && <Row className="justify-content-center">
            <Col className={colClassName} style={{ maxWidth: '487px' }}>
                <Alert className="loginCms mt-4 mb-0" variant="info">
                    <p dangerouslySetInnerHTML={{ __html: cmsData.html }} />
                </Alert>
            </Col>
         </Row>}
        <Row className="justify-content-center">
            <Col className={colClassName} style={{ maxWidth: '487px' }}>

                <Card>
                    <Card.Title className="mb-4">
                        Benefits Access Login
                    </Card.Title>
                    <Card.Body>

                        {error && attemptCount > 0 &&
                            <Alert className="loginErr" variant="danger">
                                Invalid username or password. {isLastAttempt
                                    ?
                                    "Last attempt before your account is locked."
                                    :
                                    <>It appears you may be having some <Link to={GatewayPath.NeedHelp}>Trouble Logging In?</Link> </>
                                }
                            </Alert>
                        }



                        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                            e.preventDefault();
                            login();
                        }}>

                            <Form.Group controlId="loginSsn" className={showUsernameErr ? "error" : ""}>
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="input"
                                    value={username}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                                    maxLength={20}
                                    tabIndex={1}
                                    autoFocus
                                />
                            </Form.Group>

                            <Form.Group controlId="loginPassword" className={showPasswordErr ? "error" : ""}>
                                <Form.Label>Password</Form.Label>
                                <div className="input-group">
                                    <Form.Control 
                                        type="password"
                                        value={password}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                                        maxLength={40}
                                        tabIndex={2}
                                    />
                                </div>
                                {error && (isLastAttempt || attemptCount > 1) && <div className="footnote">
                                    Do you want to <NavLink to={GatewayPath.NeedHelp} activeClassName="active" tabIndex={3}>reset your access?</NavLink>
                                </div>}
                            </Form.Group>

                            <Button variant="primary" type="submit" tabIndex={4}
                                disabled={!username || !password || props.isLoading}>
                                {props.isLoading ? <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> : <span className="mr-4"><FontAwesomeIcon icon={faLockAlt} className="mr-2"/>Secure Login</span>}
                            </Button>

                        </Form>

                        <NavLink to={GatewayPath.NeedHelp} className="nav-link mt-2" activeClassName="active" tabIndex={5}>
                            Trouble Logging In?
                        </NavLink>

                        <div className="mt-3"><input type="checkbox" tabIndex={6} checked={rememberUserName} onChange={e => setRememberUserName(e.target.checked)}/> Remember me</div>
                        <div className="small text-muted font-italic">Not recommended for shared computers.</div>

                    </Card.Body>
                </Card>
            </Col>
        </Row>

        <Row className="justify-content-center">
            <Col className={colClassName} style={{ maxWidth: '487px' }}>
                <NavLink to={GatewayPath.Registration} className="nav-link loginBox" activeClassName="active">
                    <Button variant="secondary" style={{ width: '100%' }} tabIndex={7}>New User Registration</Button>
                </NavLink>
            </Col>
        </Row >

        <Row className="justify-content-center">
            <Col className={colClassName + " termsAndConditions"} style={{ maxWidth: '487px' }}>
                <div>
                    By logging in you agree to the <br className="d-inline d-sm-none" />
                    <a href="https://www.wespath.org/about-wespath/terms-and-conditions" target="_blank" rel="noopener noreferrer" tabIndex={8} onClick={() => dispatch(activityActionCreators.logActivity("link_termsAndConditions"))}>
                        Terms and Conditions of Use
                    </a>.
                </div>
                <div>
                    Plan sponsors, please <br className="d-inline d-sm-none"/>
                    <a href="https://portal.wespath.org/LoginPage.aspx" tabIndex={9} onClick={() => dispatch(activityActionCreators.logActivity("link_planSponsorLogin"))}>log in to administer</a> your participant&apos;s records.
                </div>
            </Col>
        </Row >


    </>)
    
};

function mapStateToProps(state: AppState) {
    const regStepNum = state.loginHelp.stepNum;

    return {
        ...state.account,
        cmsData: state.cms.login,
        regStepNum
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...accountActionCreators, ...loginHelpCreators, fetchCmsData: cmsActionCreators.fetchLoginCmsData }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);

const colClassName = "col-12 col-sm-10 col-md-8 col-lg-6 loginBox";

const LoadingSkeleton = () => (
    <Row className="justify-content-center">
        <Col className={colClassName} style={{ maxWidth: '487px' }}>
            <Card>
                <Card.Title className="mb-4">
                    Benefits Access Login
                        </Card.Title>
                <Card.Body>
                    <Form>

                        <Form.Group>
                            <Skeleton />
                            <Skeleton height={25} />
                        </Form.Group>

                        <Form.Group>
                            <Skeleton />
                            <Skeleton height={25} />
                        </Form.Group>


                        <div className="my-3">
                            <Skeleton height={40} />
                        </div>
                    </Form>

                    <div className="my-3">
                        <Skeleton height={25} />
                    </div>

                    <div className="mt-3 mb-2">
                        <Skeleton count={2} />
                    </div>

                </Card.Body>
            </Card>
        </Col>
    </Row>
);