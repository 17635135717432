import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { cmsActionCreators } from '../../../store/CmsStore';
import { AppState } from '../../../store/configureStore';
import { AppDispatch } from '../../..';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';
import { PlanType, ServiceProvider } from '../../../Gateway.dtos';
import { getServiceProvider } from '../../../functions/getServiceProvider';

type TNewForYouProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;


const NewForYou = (props: TNewForYouProps) => {
    const { planElections } = props;

    const serviceProvider = getServiceProvider(planElections?.find(p => p.type === PlanType.MEDICAL)?.provider);

    const cmsNewForYouWithBCBS = props.cmsData.fragments?.find(f => f.name === "NewForYouWithBCBS")?.html ?? '';
    const cmsNewForYouWithUHC = props.cmsData.fragments?.find(f => f.name === "NewForYouWithUHC")?.html ?? '';

    const cmsIsLoading = props.cmsData.loadState !== DataLoadState.Loaded

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    return (

        <Card className="pageCard secondary">
            <Card.Title>
                <FontAwesomeIcon icon={faAsterisk} className="health-color smaller" />
                New for You in 2024
            </Card.Title>
            <Card.Body className="mb-0" style={{ paddingBottom: '0px' }}>

                {cmsIsLoading ? <LoadingSkeleton /> : <>

                    <p className="thin" dangerouslySetInnerHTML={{ __html: serviceProvider === ServiceProvider.BCBS ? cmsNewForYouWithBCBS : cmsNewForYouWithUHC }} />

                </>}


            </Card.Body>
        </Card>

    );
}

function mapStateToProps(state: AppState) {
    return {
        cmsData: state.cms.healthAE,
        planElections: state.healthPlans?.healthPlans?.planElections
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchHealthAECmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewForYou);
