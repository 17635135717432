import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartNetwork } from '@fortawesome/pro-solid-svg-icons';
import { cmsActionCreators } from '../../../store/CmsStore';
import { AppState } from '../../../store/configureStore';
import { AppDispatch } from '../../..';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PlanType, ServiceProvider } from '../../../Gateway.dtos';
import { Health } from '../../../definitions/Claims';
import { getServiceProvider } from '../../../functions/getServiceProvider';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import { DataLoadState } from '../../../definitions/IEnumLoadableState';

type THealthNetworkProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;


const HealthNetwork = (props: THealthNetworkProps) => {
    const { planElections } = props;

    const cmsIsLoading = props.cmsData.loadState !== DataLoadState.Loaded

    const cmsHealthNetworkMsg = props.cmsData.fragments?.find(f => f.name === "HealthNetworkMsg")?.html ?? '';

    const serviceProvider = getServiceProvider(planElections?.find(p => p.type === PlanType.MEDICAL)?.provider);

    if (serviceProvider !== ServiceProvider.UHC) {
        return <></>
    }

    return (
        <Card className="pageCard secondary">
            <Card.Title>
                <FontAwesomeIcon icon={faChartNetwork} className="health-color smaller" />
                Health Network Update
            </Card.Title>
            <Card.Body>

                {cmsIsLoading ? <LoadingSkeleton /> : <>

                    <p className="thin m-0" dangerouslySetInnerHTML={{ __html: cmsHealthNetworkMsg }} />

                </>}

            </Card.Body>
        </Card>
        );
}

function mapStateToProps(state: AppState) {
    return {
        cmsData: state.cms.healthAE,
        username: state.account.username,
        hasClaim_HealthAccess: state.claims.claims?.claims?.find(c => c === Health.Access),
        planElections: state.healthPlans?.healthPlans?.planElections
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchHealthAECmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HealthNetwork);
