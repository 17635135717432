import React from 'react';
import { Navbar, Row, Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { AppState } from '../store/configureStore';
import logo from '../images/WP_Logo2x.png';
import { GatewayPath } from '@wespath/gateway-navigation'
import { AppDispatch } from '..';
import { NavLink } from 'react-router-dom';
import { activityActionCreators } from '../store/ActivityStore';
import { versionNumber } from '../App';

type TFooterProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Footer = (props: TFooterProps) => {
    const dispatch: AppDispatch = useDispatch();

    return (
        <Navbar className="px-0 mt-5 pb-4 d-block" id="footer" >

            <GlobalFooter />

            {props.showInvestmentDisclaimer && (props.currentPage === GatewayPath.BenefitsSummary || props.currentPage === GatewayPath.Retirement) &&
                <div className="footnote">
                    <p>
                        The posted rates of return are as of the prior month end for the stated time period only.
                    </p>
                    <p>
                        Historical returns are not indicative of future performance. The prices of investment funds will 
                        rise and fall with the market value of the investments held in the funds. The units an accountholder 
                        owns may be worth more or less than their purchase price when redeemed. Investment performance is 
                        presented net-of-fees—that is, with the deduction of external investment management fees, custody fees, 
                        and administrative and overhead expenses. 
                    </p>
                    <p>
                        These funds are neither insured nor guaranteed by the government.
                    </p>
                    <p>
                        For more information about the funds, please refer to the Investment Funds Description – P Series.
                    </p>
                </div>
            }

            {props.currentPage === GatewayPath.Retirement && <div className="footnote">

                {props.showEyDisclaimer && <p>
                    Costs for EY Financial Planning Services are included in Wespath&apos;s operating expenses that are paid for by the funds.
                    Services are available to active participants and surviving spouses with account balances, and to retired and terminated
                    participants with account balances of at least $10,000.
                </p>}

                {props.showLifeStageDisclaimer && <p>
                    Costs for LifeStage Investment Management services are included in Wespath&apos;s operating expenses that are paid for by the funds.
                </p>}

            </div>}

            {(props.currentPage === GatewayPath.Retirement || props.currentPage === GatewayPath.BenefitsSummary) && <div className="footnote">

                {props.showProjectionDisclaimer && <p>
                    For a complete explanation of the calculations used for this projection
                    see <a href="https://www.wespath.org/assets/1/7/4258.PDF" target=" _blank" rel="noopener noreferrer" className="secondary" onClick={() => dispatch(activityActionCreators.logActivity("link_assumptionsAndMethodology"))}>Assumptions and Methodology</a>.
                </p>}

            </div>}

            {(props.currentPage === GatewayPath.BenefitsSummary || props.currentPage === GatewayPath.Health || props.currentPage === GatewayPath.WellBeing) && <div className="footnote">

                {(props.showHealth || props.showWellBeing) && <p>
                    <a href="https://www.wespath.org/health-well-being/benefit-programs/healthflex/regulatory-notices"
                        target=" _blank" rel="noopener noreferrer" className="secondary"
                        onClick={() => dispatch(activityActionCreators.logActivity("link_noticeOfPrivacyPractices"))}
                    >
                        Notice of Privacy Practices
                    </a> for HealthFlex health and well-being programs.
                </p>}

            </div>}

            {(props.currentPage === GatewayPath.BenefitsSummary ||
                props.currentPage === GatewayPath.Retirement ||
                props.currentPage === GatewayPath.Health ||
                props.currentPage === GatewayPath.WellBeing ||
                props.currentPage === GatewayPath.LifeAndDisability ||
                props.currentPage === GatewayPath.AccountManagement ||
                props.currentPage === GatewayPath.SiteMap) && <div className="footnote">
                <p>
                    This website contains links to partner websites which are not managed by Wespath. Those 
                    websites&apos; privacy policies may be different than Wespath&apos;s policy and you should make sure to review it.
                </p>

            </div>}


        </Navbar>
    );
}

function mapStateToProps(state: AppState) {
    const currentPage = state.router.location.pathname;
    const showInvestmentDisclaimer = state.investments.investments?.investments !== undefined;
    const showEyDisclaimer = state.ey.eyEligibility?.eyEligible === "Y";
    const showLifeStageDisclaimer = state.investments.investments?.investments?.findIndex(investment => investment.lifestage === true) >= 0;
    const showProjectionDisclaimer = state.benefitProjection.benefitProjection?.estAmt > 0;

    return {
        currentPage,
        showInvestmentDisclaimer,
        showEyDisclaimer,
        showLifeStageDisclaimer,
        showProjectionDisclaimer,
        ...state.displayPage
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer);

const GlobalFooter = () => {
    const dispatch: AppDispatch = useDispatch();

    return (<>
        <Row className="justify-content-between w-100 mb-4 d-print-none" >
            <Col className="col-12 col-md-2 pl-0">
                <img src={logo} id="footerLogo" alt="logo"/>
            </Col>
            <Col className="col-12 col-md-10 text-md-right mt-2 pl-0">
                <div className="d-xs-block d-sm-inline">
                    <a href="https://www.wespath.org/about-wespath/contact-us" target="_blank" rel="noopener noreferrer" className="footerLink" onClick={() => dispatch(activityActionCreators.logActivity("link_footerContactUs"))}>Contact Us</a>
                    <span className="mx-2 d-none d-sm-inline">|</span>
                </div>
                <div className="d-xs-block d-sm-inline">
                    <a href="https://www.wespath.org/about-wespath/terms-and-conditions" target="_blank" rel="noopener noreferrer" className="footerLink" onClick={() => dispatch(activityActionCreators.logActivity("link_footerTermsAndConditions"))}>Terms & Conditions</a>
                    <span className="mx-2 d-none d-sm-inline">|</span>
                </div>
                <div className="d-xs-block d-sm-inline">
                    <a href="https://www.wespath.org/about-wespath/privacy-policy" target="_blank" rel="noopener noreferrer" className="footerLink" onClick={() => dispatch(activityActionCreators.logActivity("link_footerPrivacy"))}>Privacy</a>
                    <span className="mx-2 d-none d-sm-inline">|</span>
                </div>
                <div className="d-xs-block d-sm-inline">
                    <NavLink to={GatewayPath.SiteMap} className="footerLink" onClick={() => dispatch(activityActionCreators.logActivity("link_footerSiteMap"))}>Site Map</NavLink>
                    <span className="mx-2 d-none d-sm-inline">|</span>
                </div>
                <div className="d-xs-block d-sm-inline">
                    <a href="http://www.wespath.org" target="_blank" className="footerLink" rel="noopener noreferrer" onClick={() => dispatch(activityActionCreators.logActivity("link_footerWespath.org"))}>Wespath.org</a>
                </div>

                <p className="mt-2 mt-md-4 mb-0 thin">
                    &copy; 2020 Wespath Benefits and Investments, a general agency of The United Methodist Church.
                </p>
                <p className="mt-0">
                    {versionNumber}
                </p>
            </Col>
        </Row>
    </>);

}