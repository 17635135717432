import React, { useEffect, useState } from 'react';
import { AppState } from '../../../store/configureStore';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Card, Row, Col } from 'react-bootstrap';
import LNMultiFactorPhone from './LNMultiFactorPhone';
import LNMultiFactorQuestions from './LNMultiFactorQuestions';
import LNMultiFactorEnterCode from './LNMultiFactorEnterCode';
import { accountActionCreators } from '../../../store/AccountStore';
import { lnMultiFactorActionCreators } from '../../../store/LNMultiFactorStore';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { Ruleset } from '../../../Gateway.dtos';
import { AppDispatch } from '../../..';

type TLNMultiFactorProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const LNMultiFactor = (props: TLNMultiFactorProps) => {
    const { accessDenied, multiFactor, participant, phonesLoaded, phones } = props;
    const isLoading = ((props.isLoading && !props.isLoaded) || !participant);

    const [showPhone, setShowPhone] = useState(true);
    const [showEnterCode, setShowEnterCode] = useState(false);
    const [showKba, setShowKba] = useState(false);


    useEffect(() => {
        if (stateNeedsLoading(props)) {
            props.initiateMultiFactor();
        }
    });

    useEffect(() => {
        document.title = "Benefits Access - Multi-Factor Authentication"
    }, []);

    useEffect(() => {
        //Check which multi-factor page should be displayed
        if (!isLoading) {
            if (participant.ruleset === Ruleset.KBA) {
                setShowEnterCode(false);
                setShowPhone(false);
                setShowKba(true);
            }
            else {
                const expiredCode = multiFactor.responseStatus?.message === "expired";
                const pendingChallenge = multiFactor.transactionResponse?.transactionStatus === "pending";
                const showCode = participant.phoneNumber !== "" && (pendingChallenge || expiredCode);

                setShowEnterCode(showCode);
                setShowPhone(!showCode);
                setShowKba(false);
            }
        }
    }, [participant, multiFactor, isLoading]);
    
    useEffect(() => {
        if (!isLoading) {
            const noPhoneNumber = (participant.ruleset === Ruleset.OTP && phonesLoaded && phones.length === 0);

            //Check if user should be redirected to accessDenied page
            if (noPhoneNumber || multiFactor.responseStatus?.message === "deny") {
                accessDenied();
            }
        }
    }, [phonesLoaded, phones, multiFactor, accessDenied, participant, isLoading]);


    const colClassName = "col-12 col-lg-10 col-xl-9";
    return (
        <Row className="justify-content-center">
            <Col className={colClassName}>
                <Card className="fullPageCard">
                    <Card.Title>
                        Multi-Factor Authentication
                    </Card.Title>

                    <Card.Body>

                        {showPhone && <LNMultiFactorPhone />}

                        {showEnterCode && <LNMultiFactorEnterCode />}

                        {showKba && <LNMultiFactorQuestions />}

                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

function mapStateToProps(state: AppState) {

    return {
        ...state.lnMultiFactor,
        page: state.router.location.pathname
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    const { accessDenied } = accountActionCreators;

    return bindActionCreators({
        ...lnMultiFactorActionCreators,
        accessDenied
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LNMultiFactor);