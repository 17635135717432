import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { AppState } from '../../store/configureStore';
import { bindActionCreators } from '@reduxjs/toolkit';
import { accountActionCreators } from '../../store/AccountStore';
import { AppDispatch } from '../..';
import { LoadingSkeletonFullpage } from '../LoadingSkeletonFullpage';
import { push } from 'connected-react-router';
import { GatewayPath } from '@wespath/gateway-navigation';
import { resetApplication } from '../../functions/resetApplication';

type TInboundSsoProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;


const BapInboundSso = (props: TInboundSsoProps) => {
    const dispatch: AppDispatch = useDispatch();    

    useEffect(() => {
        dispatch(resetApplication({ isBapSso: true }))
        dispatch(accountActionCreators.fetchBapSsoData())
            .then(() => dispatch(push(GatewayPath.Retirement)))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])   

    useEffect(() => {
        document.title = "Benefits Access - Inbound SSO"
    }, []);

    return (
        <LoadingSkeletonFullpage isCard />
    );

}

function mapStateToProps(state: AppState) {

    return {
        account: state.account
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({ ...accountActionCreators }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BapInboundSso);
