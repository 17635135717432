import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons';
import { cmsActionCreators } from '../../../store/CmsStore';
import { AppState } from '../../../store/configureStore';
import { AppDispatch } from '../../..';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { activityActionCreators } from '../../../store/ActivityStore';
import { LoadingSkeleton } from '../../LoadingSkeleton';

type TWhatsNewVideoProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;


const WhatsNewVideo = (props: TWhatsNewVideoProps) => {
    const cmsData = props.cmsData.fragments?.find(f => f.name === "WhatsNew");
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    return <Row className="my-4 mb-md-3 mx-lg-5 px-md-3">
        <Col className="mx-lg-4 px-md-5">

            {!cmsData ? <LoadingSkeleton /> : <>

                {cmsData && <p dangerouslySetInnerHTML={{ __html: cmsData.html }} />}

            </>}

            
        </Col>
    </Row>;
}

function mapStateToProps(state: AppState) {
    return {
        cmsData: state.cms.healthAE
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchHealthAECmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WhatsNewVideo);
