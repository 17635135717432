import React, { useEffect, useRef, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompass } from '@fortawesome/pro-solid-svg-icons';
import { cmsActionCreators } from '../../../store/CmsStore';
import { AppState } from '../../../store/configureStore';
import { AppDispatch } from '../../..';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { stateNeedsLoading } from '../../../functions/stateNeedsLoading';
import { LoadingSkeleton } from '../../LoadingSkeleton';
import SAMLSsoModal from '../../SAMLSsoModal';
import { SamlSelfPostForm } from '@wespath/gateway-navigation';
import { ServiceProvider } from '../../../Gateway.dtos';
import { Health } from '../../../definitions/Claims';

type TNewCareProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;


const NewCare = (props: TNewCareProps, hasHSA: boolean) => {
    const { username, hasClaim_HealthAccess } = props;
    const cmsData = props.cmsData?.fragments?.find(f => f.name === "NewCare");

    const formRef = useRef<HTMLFormElement>(null);
    const serviceProvider = ServiceProvider.Quantum

    const [isShowingSsoModal, setShowingSsoModal] = useState(false);

    const handleSSO = () => {
        if (sessionStorage.getItem(`${username}-sso-${ServiceProvider.Quantum}`) === "ssoSent") {
            formRef?.current?.submit();
        } else {
            setShowingSsoModal(true);
        }
    }

    useEffect(() => {
        if (stateNeedsLoading(props.cmsData)) {
            props.fetchCmsData();
        }
    });

    return (<>

        <Card className="pageCard secondary">
            <Card.Title>
                <FontAwesomeIcon icon={faCompass} className="health-color smaller" />
                New Care Coordination Services
            </Card.Title>
            <Card.Body style={{ paddingBottom: '0px' }}>

                {!cmsData ? <LoadingSkeleton /> : <>

                    {cmsData ? <p className="thin" dangerouslySetInnerHTML={{ __html: cmsData.html }} /> : null}

                    <Button className={`ml-3 ${!props.hasClaim_HealthAccess ? "claimControlled" : ""}`}
                        variant="secondary"
                        onClick={handleSSO}
                        disabled={!hasClaim_HealthAccess}
                    >
                        Medical Benefits & Care Coordination
                    </Button>

                </>}

            </Card.Body>
        </Card>
        <SamlSelfPostForm ref={formRef} provider={serviceProvider} source="b" />
        <SAMLSsoModal isShowing={isShowingSsoModal} hideModal={() => setShowingSsoModal(false)} serviceProvider={serviceProvider} referrer="b" />
    </>);
}

function mapStateToProps(state: AppState) {
    return {
        cmsData: state.cms.healthAE,
        username: state.account.username,
        hasClaim_HealthAccess: state.claims.claims?.claims?.find(c => c === Health.Access)
    }
}

function mapDispatchToProps(dispatch: AppDispatch) {
    return bindActionCreators({
        fetchCmsData: cmsActionCreators.fetchHealthAECmsData
    }, dispatch);
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewCare);
