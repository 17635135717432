import React, { ReactElement, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldCheck } from '@fortawesome/pro-solid-svg-icons';
import { AppState } from '../../../store/configureStore';
import { useSelector } from 'react-redux';
import { ServiceProvider } from '../../../Gateway.dtos';
import { getServiceProviderString } from '../../../functions/getServiceProvider';
import { SamlSelfPostForm } from '@wespath/gateway-navigation';
import { SAMLSsoModal } from '../../SAMLSsoModal';
import { Health } from '../../../definitions/Claims';


const ReviewPastClaims = (): ReactElement => {

    const hasClaim_HealthAccess = useSelector((state: AppState) => state.claims.claims?.claims?.find(c => c === Health.Access))
    const displayBsbsSso = useSelector((state: AppState) => state.healthPlans?.healthPlans?.displayBsbsSso ?? false)
    const displayUhcSso = useSelector((state: AppState) => state.healthPlans?.healthPlans?.displayUhcSso ?? false)
    const username = useSelector((state: AppState) => state.account.username)


    const serviceProvider = displayBsbsSso
        ? ServiceProvider.BCBS
        : (displayUhcSso
            ? ServiceProvider.UHC
            : ServiceProvider.None
        );

    const serviceProviderStr = getServiceProviderString(serviceProvider)

    const referrer = 'b'
    const [isShowingSsoModal, setShowingSsoModal] = useState(false);
    const formRef = React.useRef<HTMLFormElement>(null);
    const handleSubmit = () => {
        if (sessionStorage.getItem(`${username}-sso-${serviceProvider}`) === "ssoSent")
            formRef?.current?.submit();
        else
            setShowingSsoModal(true);
    }

    if (serviceProvider === ServiceProvider.None)
        return <></>

    return (
        <>
            <Card className="pageCard secondary">
                <Card.Title>
                    <FontAwesomeIcon icon={faShieldCheck} className="health-color smaller" />
                    Review Past Claims
                </Card.Title>
                <Card.Body>

                    <p>You can view claims information from 2023 or earlier by visiting {serviceProviderStr}</p>

                    <Button variant="secondary"
                        onClick={handleSubmit}
                        className={`mt-2 ${!hasClaim_HealthAccess ? "claimControlled" : ""}`}
                        disabled={!hasClaim_HealthAccess}
                    >
                        Go to {serviceProviderStr}                       
                    </Button>

                </Card.Body>
            </Card>
            <SamlSelfPostForm ref={formRef} provider={serviceProvider} source={referrer} />
            <SAMLSsoModal isShowing={isShowingSsoModal} hideModal={() => setShowingSsoModal(false)} serviceProvider={serviceProvider} username={username} referrer={referrer} />
        </>

    );
}

export { ReviewPastClaims };