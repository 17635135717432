import { AppState } from "./configureStore";
import { Authenticate, GetBenefitsAccessPlanSponsorsSsoUserInfo, Logoff, GetBenefitsAccessPlanSponsorsSsoUserInfoResponse, AuthenticateResponse } from "./../Gateway.dtos";
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ILoadableState } from "../definitions/ILoadableState";
import { push } from "connected-react-router";
import { client } from "../App";
import { GatewayPath } from '@wespath/gateway-navigation'
import { loginHelpCreators } from "./LoginHelpStore";
import { displayPageActionCreators } from "./DisplayPageStore";
import { lnMultiFactorActionCreators } from "./LNMultiFactorStore";
import { authMultiFactorActionCreators } from "./AuthMultiFactorStore";
import { AppDispatch } from "..";
import { eySearchActionCreators } from "./EySearchStore";
import { getResetAction } from "../functions/getResetAction";
import { resetApplication } from "../functions/resetApplication";
import { LsriActionCreators } from "./LsriStore";

export interface IAccountState extends ILoadableState {
    username: string
    participantNumber: string
    isAuthenticated: boolean
    isLocked?: boolean
    isPwLocked?: boolean
    name?: string
    firstName?: string
    lastName?: string
    profileName?: string
    profileFirstName?: string
    profileLastName?: string
    profileUsername?: string
    errorMessage?: string
}

export const initialAccountState: IAccountState = {
    username: "",
    participantNumber: "",
    isAuthenticated: false,
    name: "",
    firstName: "",
    lastName: "",
    profileName: "",
    profileFirstName: "",
    profileLastName: "",
    profileUsername: "",
    errorMessage: "",
    isLocked: false,
    isPwLocked: false,
    isLoaded: false,
    isLoading: false
};

interface Error {
    errorMessage: string
}

const fetchBapSsoData = createAsyncThunk<
    GetBenefitsAccessPlanSponsorsSsoUserInfoResponse,
    void,
    {
        dispatch: AppDispatch,
        state: AppState,
        rejectValue: Error
    }
>(
    'account/bapSso',
    async (_, thunkApi) => {
        try {
            
            return await client.post(new GetBenefitsAccessPlanSponsorsSsoUserInfo())
        } catch (e: unknown) {
            thunkApi.dispatch(push(GatewayPath.AccessDenied))
            return thunkApi.rejectWithValue({ errorMessage: "Unable to SSO" });
        }
        
    }
)



const accountSlice = createSlice({
    name: 'account',
    initialState: { } as IAccountState,
    reducers: {
        resetState: () => {
            return {
                ...initialAccountState
            }
        },
        requestAccountInfo: (state: IAccountState, action: PayloadAction<{ username: string }>) => {
            const { username } = action.payload;
            return {
                ...state,
                username,
                isLocked: false,
                isPwLocked: false,
                isLoading: true,
                isLoaded: false,
                error: false
            }
        },
        receiveAccountInfo: (state: IAccountState, action: PayloadAction<{ name: string, firstName: string, lastName: string, participantNumber: string, isAuthenticated: boolean }>) => {
            const { name, firstName, lastName, participantNumber, isAuthenticated } = action.payload;
            return {
                ...state,
                name,
                firstName,
                lastName,
                participantNumber,
                isAuthenticated,
                isLoading: false,
                isLoaded: true,
                error: false
            }
        },
        receiveAccountInfoError: (state: IAccountState, action: PayloadAction<{ message: string }>) => {
            const { message } = action.payload;
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                error: true,
                errorMessage: message
            }
        },
        setUsername: (state: IAccountState, action: PayloadAction<{ username: string }>) => {
            const { username } = action.payload;
            return {
                ...state,
                username
            }
        },
        setPartNum: (state: IAccountState, action: PayloadAction<{ participantNumber: string }>) => {
            const { participantNumber } = action.payload;
            return {
                ...state,
                participantNumber
            }
        },
        setAuthenticated: (state: IAccountState, action: PayloadAction<{ isAuthenticated: boolean }>) => {
            const { isAuthenticated } = action.payload;
            return {
                ...state,
                isAuthenticated
            }
        },
        setIsLocked: (state: IAccountState, action: PayloadAction<{ isLocked: boolean }>) => {
            const { isLocked } = action.payload;
            return {
                ...state,
                isLocked
            }
        },
        setIsPwLocked: (state: IAccountState, action: PayloadAction<{ isPwLocked: boolean }>) => {
            const { isPwLocked } = action.payload;
            return {
                ...state,
                isPwLocked
            }
        },
        setProfileName: (state: IAccountState, action: PayloadAction<{ profileName: string, profileFirstName: string, profileLastName: string, profileUsername: string }>) => {
            const { profileName, profileFirstName, profileLastName, profileUsername } = action.payload;
            return {
                ...state,
                profileName,
                profileFirstName,
                profileLastName,
                profileUsername
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getResetAction(), (state, action) => {
                const isEySearch = !!action?.payload?.isEySearch
                const isBapSso = !!action?.payload?.isBapSso
                const keepPwLock = action?.payload?.keepPwLock
                const isLogin = !!action?.payload?.isLogin

                if (isEySearch) {
                    return {
                        ...state,
                        participantNumber: '',
                        profileName: '',
                        profileFirstName: '',
                        profileLastName: '',
                        profileUsername: ''
                    }
                } else if (isBapSso) {
                    return {
                        ...initialAccountState,
                        isLocked: false,
                        isPwLocked: false,
                        isLoading: true,
                        isLoaded: false,
                        error: false
                    }
                } else if (keepPwLock) {
                    return {
                        ...initialAccountState,
                        isPwLocked: state.isPwLocked
                    }
                }
                else if (isLogin) {
                    return {
                        ...state
                    }
                }
                else {
                    return initialAccountState
                }
                
            })
            .addCase(fetchBapSsoData.fulfilled, (state: IAccountState, { payload }) => {
                state.name = payload.name;
                state.firstName = payload.firstName;
                state.lastName = payload.lastName;
                state.participantNumber = payload.participantNumber;
                state.username = payload.username;
                state.profileName = payload.profileName;
                state.profileFirstName = payload.profileFirstName;
                state.profileLastName = payload.profileLastName;
                state.profileUsername = payload.profileUsername;
                state.isLocked = payload.isLocked;
                state.isAuthenticated = true;
                state.isLoading = false;
                state.isLoaded = true;
                state.error = false;
                
            })
            .addCase(fetchBapSsoData.rejected, (state: IAccountState, action) => {
                state.isLoading = false;
                state.isLoaded = false;
                state.error = true;
                state.errorMessage = action.payload?.errorMessage;
            })
    }
})

const {
    resetState,
    requestAccountInfo,
    receiveAccountInfo,
    receiveAccountInfoError,
    setUsername,
    setPartNum,
    setAuthenticated,
    setIsLocked,
    setIsPwLocked,
    setProfileName,
    
} = accountSlice.actions;

function login(username: string, password: string, threatMetrixSessionId: string, redirect?: GatewayPath | string | false | undefined, rememberUserName?: boolean) {
    
    return async (dispatch: AppDispatch, getState: () => AppState): Promise<void> => {
        const { isLoaded, isLoading } = getState().account;

        if (isLoading)
            return;

        //Collect redirectFromSummary before state is reset
        const redirectFromSummary = getState().displayPage.redirectFromSummary

        //Reset states that may need to be reset
        if (isLoaded) dispatch(resetState());
        dispatch(requestAccountInfo({ username}));
        dispatch(displayPageActionCreators.resetState());
        dispatch(authMultiFactorActionCreators.resetPhones());
        dispatch(loginHelpCreators.resetState());
        dispatch(lnMultiFactorActionCreators.setIsChallenging({ isChallenging: false, redirect: undefined }));
        dispatch(authMultiFactorActionCreators.resetState());
        dispatch(eySearchActionCreators.resetState());

        redirect = redirect || GatewayPath.BenefitsSummary;

        try
        {
            await client.post(new Logoff())
            dispatch(resetApplication({ isLogin: true }));

            const auth = await client.post(new Authenticate({
                provider: "credentials",
                userName: username,
                password: password,
                meta: {
                    "threatMetrixSessionId": threatMetrixSessionId
                }
            }));

            const name = auth.meta["Name"];
            const firstName = auth.meta["FirstName"];
            const lastName = auth.meta["LastName"];
            const participantNumber = auth.meta["ParticipantNumber"];
            const twoFactorRequired = auth.meta["TwoFactorRequired"] === "true";
            const contextToken = auth.meta["ContextToken"];

            sessionStorage.setItem("PG.ContextToken", contextToken);

            dispatch(receiveAccountInfo({ name, firstName, lastName, participantNumber, isAuthenticated: true }));

            if (rememberUserName === true)
                localStorage.setItem("userName", username);
            else if (rememberUserName === false)
                localStorage.removeItem("userName");

            //Check if user should be challenged
            if (twoFactorRequired) {
                redirect = GatewayPath.Challenge;
                dispatch(authMultiFactorActionCreators.setIsChallenging());
            }

            //Reset redirectFromSummary, if it was populated
            if (redirectFromSummary)
                dispatch(displayPageActionCreators.setRedirectFromSummary({ redirectFromSummary }));            

            dispatch(push(redirect));
        }
        catch (e: unknown) {

            const response: AuthenticateResponse = e as AuthenticateResponse;
            const msg = response?.responseStatus?.message?.toLowerCase() || "Uknown error during authentication"

            dispatch(receiveAccountInfoError({ message: msg }));            

            if (msg.includes("maintenance")) {

                dispatch(resetApplication());
                dispatch(push(GatewayPath.Maintenance));
            }

            if (msg.includes("access denied")) {

                if (msg.includes("active directory")) {
                    dispatch(setIsPwLocked({ isPwLocked: true }));
                    dispatch(resetApplication({ keepPwLock: true }));
                }
                else {
                    dispatch(setIsLocked({ isLocked: true }));
                    dispatch(resetApplication());
                }
                dispatch(push(GatewayPath.AccessDenied));
            }
        }

    }
}

function logout(stateOnly?: boolean) {
    return async (dispatch: AppDispatch): Promise<void> => {

        //Delete lsri for non-participants
        //QC#16669 - Set this to false since it's deleting inappropriately in some cases
        dispatch(LsriActionCreators.deleteLsriElection({ deleteFromOracle: false }))

        dispatch(resetApplication());


        if (!stateOnly) {
            client.post(new Logoff());
            sessionStorage.clear();
            dispatch(push(GatewayPath.Logoff));
        }
    }
}

function accessDenied(reason?: string) {
    return async (dispatch: AppDispatch): Promise<void> => {
        !!reason && console.log(reason);

        dispatch(resetApplication({ keepLoginHelpRequestType: true }));

        client.post(new Logoff())
        dispatch(push(GatewayPath.AccessDenied));
    }
}

export const accountActionCreators = {
    resetState,
    login,
    logout,
    accessDenied,
    setUsername,
    setPartNum,
    setAuthenticated,
    setIsLocked,
    setProfileName,
    receiveAccountInfo,
    fetchBapSsoData
};

export const accountReducer = accountSlice.reducer;
